
nav{
    box-shadow: 0px 0px 20px 0px rgb(70 98 243 / 10%);
    padding-top: 15px !important;
    // padding-bottom: 15px !important;
    .tn-atom {  
    background-color: #ffffff !important; 
    background-position: center center;
    border-color: transparent;
    border-style: solid;
}
    img{
    background: transparent;
   
}

.telNumber{
    font-size: 22px;
  text-decoration: none;
  color: black;
  
}
}
