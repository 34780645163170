
.section7{
    padding-top: 50px;
    padding-bottom: 50px;
    color: white;
    background-image: linear-gradient(180deg, rgba(59,147,255,1) 0%, rgba(17,54,176,1) 98%);

    strong{
        color: #ffffff;
        font-size: 43px;
        font-family: 'Roboto',Arial,sans-serif;
        line-height: 1.15;
        font-weight: 400;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        font-weight: bold;
    }
    .p-2{
        color: #ffffff;
    }
    .p-5{
        margin-top: -70px;
        color: #ffffff;
        font-size: 23px;
        font-family: 'Roboto',Arial,sans-serif;
        line-height: 1.15;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
    }

    .t-input {
        height: 58px;
        margin: 0;
        height: 60px;
        padding: 0 20px;
        font-size: 16px;
        width: 100% !important;
        border: 0;
        box-sizing: border-box;
        outline: 0;
        border-radius: 0;
    }
.t-input-block {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }

    .f-form{
        display: flex;
    }
    .t-input-group {
        // display: flex;
        vertical-align: bottom;
    }
    .wrapper{
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;
        height: 55px;
        padding: 0 45px 0 20px;
        font-size: 16px;
        line-height: 1.33;
        outline: 0; 
        border: 0;
        border-radius: 10px !important;  
        color: #000000;
        background-color: #ffffff;
    }

    .btn1{
    //     background-color: rgb(13, 13, 207) !important;
    //     transition: transform 200ms ease 0s, 200ms ease 0s !important;
        height: 55px;
        width: 100% !important;
        text-align: center !important;
        color:  #ffffff !important;
        font-size: 16px;
        border: 0;
        font-weight: 700;
        cursor: pointer;
        margin: top 20px;
    }
    .d-input{
        margin-left: 10px;
    }
}
 @media(max-width: 990px){

   .free1{
    font-size: 35px !important;
   }
   .btn1{
    font-size: 20px !important;
   }
   .f-form{
    display: block !important; 
   
}
 .d-input{
        width: 150% !important;
        margin: 0 !important;
    }
}