.slider{
  text-align: center;
  margin: 0;
  width: 100%;
  display: inline-block;
 justify-content: space-between; 
}
.smallSlider{
  margin: auto;

}
.sliderBox{
  background-color: white !important;
  height: 450px;
  color: black;
  font-size: x-large;
  box-shadow: 0px 0px 20px 0px rgb(70 98 243 / 20%);
  // box-shadow: 5px 5px 20px #b0ada9;
  border-radius: 20px !important;
}

.slidersmallbox{
  font-size: x-large;
  background-color: rgb(2, 47, 150);
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.sliderImg{
  margin: auto;
  height: 220px;
  width: 70%;
  border-radius: 50% !important;
  padding: 10px 20px;
}
.sec5-p{
  margin-top: 10px;
  // font-size: bold;
 
}
.sec5-p1{
  padding-right: 30px !important;
  padding-left: 30px;
  font-size:medium;
}
button{
  color: white;
  background-color: rgb(10, 68, 205);
  border: none;
  border-radius: 5px !important;
  font-size: medium !important;
  padding: 5px 10px;
}

.slick-prev, .slick-next{
  width: 30px;
}

.slick-slide > div {
  margin:0px 20px;
}
.slick-prev:before, .slick-next:before{
  // margin:0 5px;
  color:rgb(10, 68, 205) !important;
  font-size: 30px !important;
}
.slick-dots{
  bottom: -30px !important;
}

