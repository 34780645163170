.section8{
    // background-image: url('/images2/banner face L.png');
    padding-top: 30px;
    padding-bottom: 10px;   
.p-8{
    font-size: x-large;
}
}

.img2{
    width: 28px;
    margin-top: -7px;
}
.img3{
    width: 25px;
    margin-right: 10px;
}
.p-8{
    font-size: 18px;
   
}

.h-2{
    margin-top: 20px;
    font-size: 34px !important;
    // color: white !important;
    margin-bottom: -10px;
}   
@media(max-width: 767px){
    .section8{
     text-align: center;
    }
 }

 .map {
	width: 700px !important;
	height: 350px;
	background-color: #333;
}

[class*="copyrights-pane"] {
	display: none !important;
}

[class*="ground-pane"] {
	filter: grayscale(1);
}
.telNumber{
    font-size: 22px;
  text-decoration: none;
  color: black;
  
}