.honalar{ 
justify-content: center;
    width: 100% !important ;
   height: auto;
}
img{
    text-align: center;
    justify-content: center;
}

.carousel{
    margin: auto;
    width: 85%;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.4);
}

@media (max-width: 690px) {

    h1{
        font-size: 33px !important;
    }
    
  }